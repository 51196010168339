<template>
  <div>
    <!-- dialog -->

    <van-dialog v-model="infoDialog" class="exchangeModal">
      <template #title>
        <div>
          <p style="text-align: left; padding-left: 5px">系统公告</p>
          <img src="/new/bulletinD.png" alt="" class="bulletinD" />
        </div>
      </template>
      <div class="infoContentWrap">
        <p class="title">{{ currentData.title }}</p>
        <p class="content">{{ currentData.content }}</p>
      </div>
    </van-dialog>
    <!-- dialog -->
    <!-- top -->
    <van-sticky>
      <van-nav-bar title="公告">
        <template #left>
          <van-icon
            name="arrow-left"
            @click="$router.push($cookie.get('prevPage') || '/')"
          />
        </template>
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <div class="wrap">
      <!-- class="bulletinList" -->
      <div
        class="list"
        v-for="list in annList"
        :key="list.id"
        @click="showContent(list)"
      >
        <p class="title">
          {{ list.title }}
          <span class="date">{{ list.created_at }}</span>
        </p>
        <p class="content">
          {{ list.content }}
        </p>
        <p class="showMore">
          查看详情
          <img src="/new/arrowr.png" alt="" />
        </p>
      </div>
      <!-- <van-cell-group
        inset
        v-for="list in annList"
        :key="list.id"
        @click="showContent(list)"
        style="margin-top: 10px"
      >
        <van-cell :title="list.created_at" :label="list.title" />
      </van-cell-group> -->
      <div v-if="!annList.length" class="noData">暂无公告</div>
    </div>
    <foot />
  </div>
</template>
<script>
import foot from "../../components/footer";
// import { mapState } from "vuex";
export default {
  components: { foot },
  data() {
    return {
      currentOrder: {},
      setOrderModal: false,
      orderForm: {},
      orderErrorModal: false,
      errorSelect: false,
      errorType: "",
      errorTypeCh: "",
      files: [],
      paginate: {},
      annList: [],
      currentPage: 1,
      current_page: 1,
      next_page: false,
      currentData: {},
      infoDialog: false,
    };
  },
  computed: {},
  watch: {
    currentPage() {
      this.getAnn();
    },
  },
  methods: {
    showContent(list) {
      this.currentData = JSON.parse(JSON.stringify(list));
      this.infoDialog = true;
      // this.$dialog.confirm({
      //   title: list.title,
      //   message: list.content,
      //   showCancelButton: false,
      //   messageAlign: "left",
      // });
    },
    onCopy() {
      this.$toast.success("已复制");
    },
    getAnn() {
      this.$http.get(`/mobile/anns?page=${this.current_page}`).then((res) => {
        if (res.data.code == 0) {
          let listData = JSON.parse(JSON.stringify(this.annList));
          res.data.data.forEach((data) => {
            let have = false;
            this.annList.forEach((e) => {
              if (e.id == data.id) {
                have = true;
              }
            });
            if (!have) {
              listData.push(data);
            }
          });
          this.annList = listData;
          this.next_page = res.data.next_page;
          this.current_page = res.data.current_page;
        }
      });
    },
    scrollPage() {
      if (window.scrollY + window.screen.height >= document.body.scrollHeight) {
        if (this.next_page) {
          this.current_page += 1;
          this.getAnn();
        }
      }
    },
  },
  created() {
    this.getAnn();
  },
  mounted() {
    window.addEventListener("scroll", this.scrollPage);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollPage);
  },
};
</script>

<style lang="scss" scoped>
.paginate {
  margin-top: auto;
  margin-bottom: 30px;
}
.wrap {
  // background-image: url("/home-page_bg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.footBtn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.dialogInfo {
  padding: 0 5px;
  color: rgb(219, 99, 101);
  font-size: 14px;
}
.bulletinList {
  position: relative;
  cursor: pointer;
  ::before {
    content: "";
    position: absolute;
    left: 3px;
    width: 8px;
    height: 8px;
    top: 50%;
    transform: translateY(-50%);
    background-color: red;
    border-radius: 50%;
  }
  // background-color: red;
}
.noData {
  width: 100%;
  display: flex;
  justify-content: center;
}

.list {
  background-color: #fff;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 15px;
  .title {
    font-weight: bold;
    color: black;
    display: flex;
    justify-content: space-between;
    .date {
      color: #b8b6b7;
    }
  }
  .content {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #b8b6b7;
    margin-top: 10px;
  }
  .showMore {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 0.5px solid #eaeaea;
    img {
      height: 13px;
    }
  }
}

.exchangeModal {
  // position: relative;
  overflow: unset !important;
  .bulletinD {
    position: absolute;
    width: 90px;
    z-index: -1;
    top: -39px;
    right: 8px;
  }
  .infoContentWrap {
    padding: 15px;
    .title {
      font-weight: bold;
      margin-bottom: 15px;
      font-size: 14px;
    }
    .content {
      font-size: 12px;
    }
  }
}
</style>
